.title {
  text-align: center;

  &:after {
    display: block;
    content: "";
    width: 3.25em;
    height: 2px;
    margin: 0.4em auto 0.7em auto;
    border-radius: 2px;
    background-image: linear-gradient(90deg, #9c9c9c, #afafaf, #9f9f9f);
  }
}
