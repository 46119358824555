.container {
  border-bottom: solid 1px #dddddd;
  width: 100%;
  background-color: #fff;
  padding: 4em 4em 2em 4em;
  text-align: center;
  color: #2c2c2c;

  .contents {
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin: auto;
    padding: 0;
    list-style: none;
    cursor: default;
    vertical-align: middle;

    li {
      margin: 1% 0;
      min-width: 180px;
      width: 18%;
      min-width: 195px;
      padding: 1em;
      text-align: center;

      @media screen and (max-width: 1200px) {
        width: 40%;
      }

      @media screen and (max-width: 480px) {
        width: 70%;
      }

      h4 {
        font-weight: 350;
        border-bottom: 1px solid #2c2c2c;
        display: inline-block;
      }

      h5 {
        font-weight: 300;
      }
    }
  }
}
