.nav {
  height: 50px;
  vertical-align: middle;
  margin-bottom: 2rem !important;
  border-bottom: solid 1px #dddddd;

  .active {
    background-color: #6c757d !important;
    color: #fff;
  }
}
