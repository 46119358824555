.header {
  width: 100vw;
  height: 60vh;
  position: absolute;
  top: 0;
  left: 0;

  .main_title {
    font-size: 5rem;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10%;
    margin: 0;
    line-height: 0.9;
  }

  .title {
    display: inline-block;
    padding: 0 10px;
  }

  .content {
    margin: -10px 10px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 0 10px;
  }
}

.project_name {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 30px;
  z-index: 3;
  right: 10px;
  color: #ccc;
  text-align: right;
  vertical-align: bottom;
  font-size: 1rem;
}

.img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  // filter가 동작하지 않음
  &:after {
    background: hsla(0, 0, 0, 0.5);
    position: absolute;
    z-index: 2;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.indicators {
  bottom: 0.5em;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;

  li {
    &::before {
      background: rgba(255, 255, 255, 0.35);
      border-radius: 100%;
      content: "";
      display: inline-block;
      height: 0.4em;
      left: 50%;
      margin: -0.4em 0 0 -0.4em;
      position: absolute;
      text-indent: 0;
      top: 50%;
      width: 0.4em;
    }

    cursor: pointer;
    display: inline-block;
    height: 1em;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-indent: 2em;
    width: 1em;
  }

  .active {
    &::before {
      background-color: #fff;
    }
  }
}
