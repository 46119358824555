.container {
  padding: 6rem 2rem 2rem 2rem;
}

.form {
  margin: auto;
  width: 100%;
  border-radius: 20px;
  padding: 3em 3em 2em 3em;
  box-shadow: 0 7px 35px 0 rgba(90, 97, 105, 0.05), 0 10px 17px 0 rgba(90, 97, 105, 0.1),
    0 4px 8px 0 rgba(90, 97, 105, 0.06), 0 2px 3px 0 rgba(90, 97, 105, 0.11);

  textarea {
    resize: none;
    min-height: 400px;
  }
}
