.container {
  border-top: solid 1px #dddddd;
  padding: 4em 4em 4em 4em;

  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      min-width: 350px;
      margin-left: 1em;
      margin-right: 1em;
      margin-top: 3em;
      padding: 0;
      text-align: center;

      @media screen and (max-width: 1470px) {
        flex-basis: 34%;
        display: flex;
        flex-direction: column;
      }

      @media screen and (max-width: 480px) {
        width: 60%;
      }
      .img_container {
        cursor: pointer;
        margin: auto;
        width: 350px;
        position: relative;
        z-index: 1;

        img {
          width: 10em;
          height: 10em;
          border-radius: 50%;

          box-shadow: 0 7px 14px 0 rgba(33, 37, 41, 0.1), 0 2px 4px 0 rgba(33, 37, 41, 0.1);
        }

        .img_title {
          position: absolute;
          bottom: 0;
          right: 10%;
          padding: 0.1em 0.5em;
          font-size: 1.2em;
          color: white;
          background-color: rgba(33, 37, 41, 0.7);
        }
      }

      .info {
        padding: 0 1em;
        min-width: 350px;
        width: 80%;
        word-break: keep-all;
        position: relative;
        z-index: 0;
        color: #555;
        margin: auto;

        &:before {
          content: "";
          position: absolute;
          border: 1px solid #ccc;
          top: -90%;
          right: 0;
          bottom: -10%;
          left: 0;
          display: block;
          z-index: -1;
          border-radius: 18px;
          box-shadow: 0 7px 14px 0 rgba(33, 37, 41, 0.1), 0 2px 4px 0 rgba(33, 37, 41, 0.1);
        }
        a {
          color: #555;
        }
      }

      .card_container {
        min-width: 220px;
        width: 70%;
        margin: 1em auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .card {
          margin-top: 0.5em;
          width: 50%;
        }

        .title {
          padding-right: 1%;
          font-size: 0.8em;
        }

        .content {
          padding-left: 3px;

          .unit {
            padding-left: 3px;
            font-size: 0.7em;
          }
        }
      }
    }
  }
}

.marker {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #999;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  &:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
  }
}

.marker_title {
  position: absolute;
  top: 20px;
  font-size: 12px;
  width: 60px;
  left: -35px;
  font-weight: 700;
  background: #fff;
  text-align: center;
  border-radius: 30px;
}
