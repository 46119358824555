.container {
  padding: 1em 2em 1em 2em;
  a:link {
    text-decoration: none;
  }

  .card_container {
    margin: auto;

    .card {
      margin: 3% 10px;
      width: 30%;
      border-radius: 18px;
      box-shadow: 0 7px 35px 0 rgba(90, 97, 105, 0.05), 0 10px 17px 0 rgba(90, 97, 105, 0.1),
        0 4px 8px 0 rgba(90, 97, 105, 0.06), 0 2px 3px 0 rgba(90, 97, 105, 0.11);
      background-color: #ffffff;

      @media screen and (max-width: 980px) {
        max-width: 500px;
        min-width: 260px;
        width: 60%;
        height: 100%;
      }

      @media screen and (max-width: 767px) {
        margin: 3% auto;
      }

      .img {
        width: 100%;
        height: 70%;
        border-radius: 18px 18px 0 0;
        object-position: center;
        object-fit: cover;
      }

      .contents {
        padding: 15px 48px 20px 35px;
      }
    }
  }
}
