@mixin coverer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  min-height: 220px;
  color: #fff;
  text-align: center;
  width: 100vw;
  height: 60vh;
  z-index: -1;
  overflow: hidden;

  &:before {
    background: hsla(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &,
  video,
  .viewport_header {
    @include coverer;
    height: 60vh;
  }

  video {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }

  .main_title {
    font-size: 5rem;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10%;
    margin: 0;
    left: 0;
    line-height: 0.9;
  }

  .title {
    display: inline-block;
    padding: 0 10px;
  }

  .content {
    margin: -10px 10px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 0 10px;
  }
}

.viewport_header {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline {
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: #c6c6c6;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @media screen and (max-width: 767px) {
      margin: 0 9px;
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 43px auto auto auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 767px) {
      margin: 22px auto 0 0;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      border: 1px solid #acacac;
    }
  }

  .title_content {
    width: 50%;
    float: left;
    margin-top: 22px;
    position: relative;

    &:before {
      content: "";
      width: 36%;
      height: 1px;
      background: #c6c6c6;
      margin: auto 0;
      position: absolute;
      top: 0;
      right: 13px;
      bottom: 0;

      @media screen and (max-width: 767px) {
        left: 13px;
      }

      @media screen and (max-width: 480px) {
        width: 30%;
      }
    }

    .title {
      font-size: 40px;
      z-index: 1;
      margin-left: 30%;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 767px) {
      width: 95%;
      margin-top: 0;
      float: right;
      text-align: center;
    }
  }

  .content {
    width: 50%;
    padding: 20px 0 20px 50px;
    float: right;
    border: 1px solid #acacac;
    margin-right: -20px;
    margin-left: 30px;
    border-radius: 18px;

    @media screen and (max-width: 767px) {
      width: 95%;
      margin-right: 0;
    }
  }
}

.project_name {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 10px;
  z-index: 3;
  right: 10px;
  color: #ccc;
  text-align: right;
  vertical-align: bottom;
  font-size: 1rem;
}
