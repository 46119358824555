.footer {
  position: relative;
  background-color: #3c3c3c;
  z-index: 11;
}

.logo {
  margin-top: auto;
  margin-bottom: auto;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .item {
    margin-bottom: 2px;
    margin-right: 10px;

    .title {
      font-weight: 700;
      margin-right: 5px;
    }

    .contents {
      margin-right: 5px;
    }
  }
}

.contents {
  font-weight: 400;
}

.copyright {
  background-color: rgba(0, 0, 0, 0.2);
}
