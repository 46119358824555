.main {
  background-color: #fff;
  width: 100%;
  margin: auto;
  border-radius: 0.25em;
}

.jumbotron {
  background-color: transparent;
}

.home_container {
  border-top: solid 1px #dddddd;
  padding: 4em 4em 2em 4em;
  margin: auto;
  text-align: center;
}

.logo_font {
  font-family: "Fugaz One", cursive;
}

h1,
h2,
h3 {
  font-weight: 350;
}

h4,
h5 {
  font-weight: 350;
}

div {
  font-weight: 350;
}

a {
  color: #343a40;
}

.navbar .nav-item .dropdown-menu {
  margin-top: 0;
  transition: max-height 300ms ease-in-out;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;

  &.show {
    opacity: 1;
    max-height: 50vh;
  }
}

html,
body {
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  overflow: hidden;
}

body {
  margin: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.main {
  background: white;
  position: relative;
  padding: 1rem;
  margin-top: 60vh;
  z-index: 11;
  width: 100vw;

  &::before {
    content: "";
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    top: -100vh;
  }
  p {
    max-width: 600px;
    margin: 1rem auto;
  }
}
